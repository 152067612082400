import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import Notifications from '@kyvg/vue3-notification'
import {createGtm} from '@gtm-support/vue-gtm';
import VueLazyLoad from 'vue3-lazyload';
import {createHead} from '@unhead/vue'
import {createVfm} from 'vue-final-modal'
import 'vue-final-modal/style.css'
import VueTippy from 'vue-tippy'

// Create a global head instance
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'PZTKD';
const head = createHead()

const gtm = createGtm({
    id: 'G-5HFCY4VC04', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
})
const vfm = createVfm()
const app = createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/**/*.vue')),
    setup({el, App, props, plugin}) {
        return createApp({render: () => h(App, props)})
            .use(plugin)
            .use(Notifications)
            .use(ZiggyVue, Ziggy)
            .use(VueLazyLoad)
            .use(head)
            .use(vfm)
            .use(VueTippy,
                {
                    directive: 'tippy', // => v-tippy
                    component: 'tippy', // => <tippy/>
                    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
                    defaultProps: {
                        placement: 'auto-end',
                        allowHTML: true,
                    }, // => Global default options * see all props
                })
            .mount(el);
    },
    progress: {
        color: 'red',
    },

});

/* TODO: Enable on prod
router.on('start', (event) => {
    console.log(event);
    gtag("js", new Date());
    gtag('event', 'page_view', {
        'page_location': event.detail.visit.url
    });
    gtag("config", "G-5HFCY4VC04");
});
 */
